<template>
<main class="container-fluid">
  <!-- desktop -->
  <div class="mainFooter">
    <!-- left -->
    <div class="FooterPageLinks">
      <b-link :to="{ name: 'terms'}" class="mb1">{{$t('message.terms_of_use')}}</b-link>
      <b-link :to="{ name: 'privacy'}">{{$t('message.privacy_policy')}}</b-link>
    </div>
    <!-- center -->
    <div class="FooterPageLinks">
      <b-link :to="{ name: 'about'}" class="mb1">{{$t('message.about_us')}}</b-link>
      <b-link :to="{ name: 'contact'}">{{$t('message.contact')}}</b-link>
    </div>
    <!-- right -->
    <div class="FooterPageLinks">
      <b-link :to="{ name: 'affiliate'}" class="mb1">{{$t('message.affiliate_program')}}</b-link>
      <b-link :to="{ name: 'affiliate'}" class="invisible">{{$t('message.affiliate_program')}}</b-link>
    </div>
  </div>

  <!-- mobile -->
    <div class="mainFooterMobile">
    <!-- left -->
    <div class="FooterPageLinks">
      <b-link :to="{ name: 'terms'}" class="mb1">{{$t('message.terms_of_use')}}</b-link>
      <b-link :to="{ name: 'privacy'}" class="mb1">{{$t('message.privacy_policy')}}</b-link>
      <b-link :to="{ name: 'about'}" class="mb1">{{$t('message.about_us')}}</b-link>
    </div>
    <!-- right -->
    <div class="FooterPageLinks">
      <b-link :to="{ name: 'contact'}" class="mb1">{{$t('message.contact')}}</b-link>
      <b-link :to="{ name: 'affiliate'}" class="mb1">{{$t('message.affiliate_program')}}</b-link>
      <b-link :to="{ name: 'affiliate'}" class="invisible">{{$t('message.affiliate_program')}}</b-link>
    </div>
  </div>

  <div class="mainFooter pt-2">
    <div>
      <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25 mr-1">
          {{$t('message.copyright')}}  © {{ new Date().getFullYear() }}
          <span
            class="ml-25 font-weight-bold">Safetravel.Today</span>
          <span class="d-sm-inline-block">, {{$t('message.allrights')}}</span>
        </span>
      </p>
    </div>
    <div>
      <span class="float-md-right d-none d-md-block">{{$t('message.handcrafted')}} {{$t('message.madewith')}} 
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
      </span>
    </div>
  </div>


</main>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
