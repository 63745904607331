/* eslint-disable */
// prettier-ignore
//icons TypeIcon, CreditCardIcon, BoldIcon, ActivityIcon, TypeIcon, LogInIcon, InfoIcon, HeartIcon
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Landing Page', route: { name: 'landing' }, icon: 'HomeIcon', isBookmarked: false },
      { title: 'Home', route: { name: 'home' }, icon: 'HomeIcon', isBookmarked: false },
      { title: 'Profile', route: { name: 'profile' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Quotation', route: { name: 'quotation' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Payments', route: { name: 'payments' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Offers', route: { name: 'offers' }, icon: 'ListIcon', isBookmarked: false },
      { title: 'Sales', route: { name: 'sales' }, icon: 'BarChartIcon', isBookmarked: false },
      { title: 'Terms of Use', route: { name: 'terms' }, icon: 'InfoIcon', isBookmarked: false },
      { title: 'Privacy Policy', route: { name: 'privacy' }, icon: 'InfoIcon', isBookmarked: false },
      { title: 'About Us', route: { name: 'about' }, icon: 'HeartIcon', isBookmarked: false },
      { title: 'Contact Us', route: { name: 'contact' }, icon: 'MailIcon', isBookmarked: false },
      { title: 'Affiliate Program', route: { name: 'affiliate' }, icon: 'BoldIcon', isBookmarked: false },
    ],
  },
}
/* eslint-enable */
